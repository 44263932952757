import React from "react"

type VideoProps = {
  source: string
  renderVideo: boolean
}

const Video = ({ source, renderVideo }: VideoProps) => {
  if (renderVideo === true) {
    return (
      <video id="background-video" loop autoPlay muted width="100%">
        <source src={source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )
  }

  return <div> </div>
}

export default Video
