/** @jsx jsx */
import { Footer as ThemeFooter, Styled, Flex, jsx } from "theme-ui"

const Footer = () => (
  <ThemeFooter>
    &copy; {new Date().getFullYear()} Blue Monocle Software. All Rights Reserved. Blue Monocle Software,
    bluemsoftware.com
    <br />
    <Flex
      sx={{
        justifyContent: `center`,
        alignItems: `center`,
        mt: 3,
        color: `text`,
        fontWeight: `semibold`,
        a: { color: `text` },
      }}
    />
  </ThemeFooter>
)

export default Footer
